<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>
      <Dialouge v-if="item.cmdbAssetInfo">
        <template v-slot:dialogueHead>
          {{ item.goodsName }}
        </template>
        <template v-slot:dialogueBody>
          <strong class="tit_dialogue">{{ item.goodsName }}</strong>
          <dl class="info_dialogue">
            <dt>가격 :</dt>
            <dd>
              {{ item.cmdbAssetInfo.price ? item.cmdbAssetInfo.price : "-" }}
            </dd>
            <dt>입고처 :</dt>
            <dd>
              {{ item.cmdbAssetInfo.partnerName ? item.cmdbAssetInfo.partnerName : "-" }}
            </dd>
            <dt>보증기간 :</dt>
            <dd>
              {{ item.cmdbAssetInfo.warranty ? item.cmdbAssetInfo.warranty + "개월" : "-" }}
            </dd>
            <dt>자산상태 :</dt>
            <dd>{{ item.cmdbAssetInfo.assetStatus }}</dd>
          </dl>
        </template>
      </Dialouge>
      <span v-else>{{ item.goodsName }}</span>
    </td>
    <td>{{ item.accountName }} ({{ item.accountCode }})</td>
    <td>{{ item.madeIn }}</td>
    <td>{{ item.amount }}</td>
    <td>{{ item.unitPrice | currency }}</td>
    <td>{{ item.price | currency }}</td>
    <td>{{ item.projectCode }}</td>
    <td class="td_ellip" :title="item.note">
      {{ item.note }}
    </td>
  </tr>
</template>

<script>
import Dialouge from "@/_approval/components/common/dialouge/Dialouge";
import InputAutoMoney from "@/_approval/components/common/input/InputAutoMoney";
import Input from "@/_approval/components/common/input/Input";
import { getMoneyToNumber } from "@/utils/stringUtils";

export default {
  name: "DraftPurchaseGoodsListLine",
  components: {
    Dialouge,
    InputAutoMoney,
    Input,
  },
  props: {
    index: Number,
    item: Object,
    isDisabledRemove: Boolean,
  },
  computed: {
    price() {
      const { unitPrice, amount } = this.item;

      if (!unitPrice || !amount) return "0";

      const nUnitPrice = getMoneyToNumber(unitPrice);
      const nAmount = getMoneyToNumber(amount);

      const nResult = nUnitPrice * nAmount;

      const strResult = String(nResult);

      return strResult;
    },
  },
  // watch: {
  //   price(value) {
  //     this.item.price = value;
  //   },
  // },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
  },
};
</script>
